import * as React from "react";
import HomeButton from "../../Common/HomeButton/HomeButton";
import Logo from "../../Common/Logo/Logo";
import useMediaQuery from "../../Utils/mediaQueries";
import "./Navbar.scss";
const arrow = "images/icons/arrow.svg";

function Navbar(props) {
  const mobileQuery = useMediaQuery("mobileBig");
  const [sortOrder, setSortOrder] = React.useState("id-a");

  const handleRaritySort = () => {
    setSortOrder(sortOrder !== "rank-a" ? "rank-a" : "rank-d");
    props.handleSortRank();
  };
  const handleIdSort = () => {
    setSortOrder(sortOrder !== "id-a" ? "id-a" : "id-d");
    props.handleSortID();
  };

  return (
    <header className="ranking-header">
      <div className="btn-logo">
        <HomeButton horizontal={true} />
        {mobileQuery.matches ? (
          <Logo smallLogo={true} addClass="nav-logo"></Logo>
        ) : null}
      </div>
      <input
        id="search-input"
        className="search"
        placeholder={"Search"}
        onChange={props.handleSearch}
        value={props.value}
      ></input>

      <div className="sort">
        <span>SORT BY</span>
        <button onClick={handleRaritySort}>
          RARITY
          <object
            aria-label="button arrow"
            className={`arrow ${
              sortOrder === "rank-a" ? "arrow-active-ascending" : ""
            } ${sortOrder === "rank-d" ? "arrow-active-descending" : ""}`}
            data={arrow}
          ></object>
        </button>
        <button onClick={handleIdSort}>
          ID
          <object
            aria-label="button arrow"
            className={`arrow ${
              sortOrder === "id-a" ? "arrow-active-ascending" : ""
            } ${sortOrder === "id-d" ? "arrow-active-descending" : ""}`}
            data={arrow}
          ></object>
        </button>
      </div>
      {mobileQuery.matches ? null : (
        <Logo smallLogo={true} addClass="nav-logo"></Logo>
      )}
    </header>
  );
}

export default Navbar;
