import "./NavSide.scss";
import Logo from "../../Common/Logo/Logo";
import HomeButton from "../../Common/HomeButton/HomeButton";

export default function NavSide(props) {
  return (
    <nav className="nav-side">
      <HomeButton></HomeButton>
      <div className="logo-wrapper">
        <Logo smallLogo={true} addClass="nav-logo"></Logo>
      </div>
    </nav>
  );
}
