import * as React from "react";
import "./NftCard.scss";

export default function NftCard(props) {
  const { name, total_score, image, rank } = props.data;

  return (
    <div className="nft-card gradient-moving">
      {/* <div className="card-logo">
        <object aria-label="logo" data={logo}></object>
      </div> */}
      <div className="inner-body">
        <span className="title">{name}</span>
        <img src={image} alt={name} className="bordered-img"></img>
        <div className="stats">
          <span>{`Rank: ${rank}`}</span>
          <span>{`Score: ${Number(total_score).toFixed(1)}`}</span>
        </div>
      </div>
      <div className="dashed"></div>
    </div>
  );
}
