import { useEffect, useRef } from "react";
import { useOnScreen } from "../../Utils/utils";
import "./MintSection.scss";
import data from "../../cardsData.json";
import CardsMenu from "./CardsMenu/CardsMenu";
import useMediaQuery from "../../Utils/mediaQueries";
import CardsMenuMobile from "./CardsMenuMobile/CardsMenuMobile";

const arrow = "images/icons/arrow.svg";

function MintHeader(props) {
  const linkRef = useRef();
  const isVisible = useOnScreen(linkRef);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        linkRef.current.classList.add("animate-link");
      }, 200);
      setTimeout(() => {
        linkRef.current.classList.add("mint-navlink-hover");
      }, 700);
    } else {
      linkRef.current.classList.remove("animate-link");
      linkRef.current.classList.remove("mint-navlink-hover");
    }
  }, [isVisible]);

  return (
    <div className="mint-header">
      <h2 className="mint-hero">BUY NOW</h2>
      <div className="button-container">
        <a href="https://app.ebisusbay.com/collection/busy-bears-club" ref={linkRef} className="mint-navlink">
          <object
            aria-label="button arrow"
            data={arrow}
            className="arrow"
          ></object>
          <span className="span-shown">CHECK OUT</span>
          <span className="span-hidden">NOW LIVE</span>
        </a>
      </div>
    </div>
  );
}

export default function MintSection(props) {
  const sectionRef = useRef();
  const isVisible = useOnScreen(sectionRef);
  const mobileQuery = useMediaQuery("mobile");
  useEffect(() => {
    if (isVisible) props.setActive("mint");
  }, [isVisible, props]);
  return (
    <section ref={sectionRef} id="mint" className="mint-section">
      <MintHeader />

      {mobileQuery.matches ? (
        <CardsMenuMobile data={data}></CardsMenuMobile>
      ) : (
        <CardsMenu data={data} />
      )}
    </section>
  );
}
