import "./Logo.scss";
const logoImg = "images/icons/justLogo.svg";
const logoFullImg = "images/icons/logo.svg";

export default function Logo(props) {
  return (
    <div className={`logo ${props.size ?? ""} ${props.addClass ?? ""}`}>
      <object
        aria-label="logo"
        style={props.style ?? {}}
        data={props.smallLogo ? logoImg : logoFullImg}
      ></object>
    </div>
  );
}
