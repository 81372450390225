import "./MenuButton.scss";

export default function MenuButton(props) {
  return (
    <button
      ref={props.refIn}
      onClick={props.onClick}
      className={`menu-button ${props.className ?? ""}`}
    >
      <div
        className={`button-line move-right ${
          props.open ? "cross-top-animate-forward" : "cross-top-animate-reverse"
        }`}
      ></div>
      <div
        className={`button-line move-left ${props.open ? "line-hidden" : ""}`}
      ></div>
      <div
        className={`button-line move-right ${
          props.open
            ? "cross-bottom-animate-forward"
            : "cross-bottom-animate-reverse"
        }`}
      ></div>
    </button>
  );
}
