import { Grid, Pagination, PaginationItem } from "@mui/material";
import Box from "@mui/material/Box";
import NftCard from "../../componentsRanking/Card/NftCard";
import metadata from "../../metadataRarity.json";
import styles from "./styles";
import { getByQuery, sortArr } from "../../Utils/queryLogic";
import { useEffect, useState } from "react";

const arrow = "images/icons/arrow.svg";

const ArrowLeft = () => (
  <object
    data={arrow}
    aria-label="arrow pagination left"
    style={{ height: "1.2rem" }}
  />
);
const ArrowRight = () => (
  <object
    data={arrow}
    aria-label="arrow pagination right"
    style={{ height: "1.2rem", transform: "rotate(180deg)" }}
  />
);

const getChunk = (dataArr, range) => {
  const [min, max] = range;
  const chunk = dataArr.filter((element, index) => {
    if (index >= min && index < max) return element;
    return false;
  });
  return chunk;
};

export default function Viewer(props) {
  const range = 20;
  const [page, setPage] = useState(1);
  let itemsCount = metadata.length;

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const calcRange = (range, page) => [
    (page - 1) * range,
    (page - 1) * range + range,
  ];
  const getItems = (dataArr) => {
    const query = props.query;
    const sort = props.sort;
    let arrModified = [...dataArr];
    if (query.length) {
      arrModified = getByQuery(dataArr, query);
      itemsCount = arrModified.length;
    }
    if (sort.attr.length) {
      arrModified = sortArr(arrModified, sort);
    }
    return getChunk(arrModified, calcRange(range, page));
  };

  useEffect(() => {
    setPage(1);
  }, [props.query]);

  return (
    <Box>
      <Box sx={styles.gridContainer}>
        <Grid container spacing={4}>
          {getItems(metadata).map((entry) => (
            <Grid item key={entry.name} xs={12} sm={6} md={3} lg={2.4}>
              <NftCard data={entry}></NftCard>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={styles.pagination}>
        <Pagination
          onChange={handlePageChange}
          shape="rounded"
          size="large"
          siblingCount={1}
          boundaryCount={3}
          count={Math.ceil(itemsCount / range)}
          renderItem={(item) => (
            <PaginationItem
              components={{ previous: ArrowLeft, next: ArrowRight }}
              {...item}
            />
          )}
        ></Pagination>
      </Box>
    </Box>
  );
}
