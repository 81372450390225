import { useCallback, useEffect, useRef, useState } from "react";
import "./Navigation.scss";
import MenuButton from "../../Common/MenuButton/MenuButton";
import Logo from "../../Common/Logo/Logo";
import DiscordLink from "../../Common/DiscordLink/DiscordLink";
import TwitterLink from "../../Common/TwitterLink/TwitterLink";

const UpperNavbar = (props) => {
  return (
    <div ref={props.refIn} className="upper-navbar">
      <Logo smallLogo={true} addClass="nav-logo"></Logo>
      <div className="last">
        <DiscordLink href="https://discord.gg/5Y4mjweQR6" />
        <TwitterLink href="https://twitter.com/BusyBearsClub" />
        <MenuButton
          open={props.open}
          onClick={props.handleOpenMenu}
        ></MenuButton>
      </div>
    </div>
  );
};

const LowerNavbar = (props) => {
  const [menuHeight, setHeight] = useState(0);
  const menuRef = useRef();
  const buttonRef = useRef();

  const handleScroll = useCallback(() => {
    if (!props.open && window.scrollY < menuHeight) {
      props.navRef.current.classList.remove("nav-padding");
      menuRef.current.classList.remove("sticky");
      buttonRef.current.classList.add("hidden");
      return;
    }
    if (props.open && window.scrollY > menuHeight) {
      menuRef.current.classList.add("sticky");
      props.navRef.current.classList.add("nav-padding");
      buttonRef.current.classList.remove("hidden");
    } else {
      menuRef.current.classList.remove("sticky");
      props.navRef.current.classList.remove("nav-padding");
      buttonRef.current.classList.add("hidden");
    }
  }, [menuHeight, props.navRef, props.open]);

  const handleCustomScroll = (e) => {
    if (e.currentTarget.id === "Rarity") {
      window.location.href = "#rarity";
      e.preventDefault();
      const offsetTop = props.rarityRef.current.offsetTop;
      const viewPortHeight = window.innerHeight;
      const height = props.rarityRef.current.getBoundingClientRect().height;
      const offset = 20;
      window.scroll({
        top: offsetTop - viewPortHeight + height + offset,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const openMenu = (isOpen) => {
    if (isOpen) {
      menuRef.current.classList.add("menu-open");
    } else {
      menuRef.current.classList.remove("menu-open");
    }
  };

  const applyActiveClass = (name) => {
    if (name.toLowerCase() === props.activeSection)
      return `nav-link nav-link-active`;
    return "nav-link";
  };

  useEffect(() => {
    setHeight(props.upperRef.current.clientHeight);
    openMenu(props.open);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [props.open, props.upperRef, handleScroll]);

  return (
    <div ref={menuRef} className="lower-navbar">
      <div className="inner-body">
        {props.links.map(([name, link]) => (
          <a
            id={name}
            className={applyActiveClass(name)}
            key={name}
            href={link}
            onClick={handleCustomScroll}
          >
            {name}
          </a>
        ))}
      </div>
      <div className="dummy-container">
        <MenuButton
          refIn={buttonRef}
          className="hidden"
          open={props.open}
          onClick={props.handleOpenMenu}
        ></MenuButton>
      </div>
    </div>
  );
};

export default function Navigation(props) {
  const navRef = useRef();
  const upperRef = useRef();
  const [open, setOpen] = useState(false);

  const handleOpenMenu = () => {
    setOpen(open ? false : true);
  };
  return (
    <nav ref={navRef} className="navigation">
      <UpperNavbar
        refIn={upperRef}
        open={open}
        handleOpenMenu={handleOpenMenu}
      ></UpperNavbar>
      <LowerNavbar
        activeSection={props.activeSection}
        navRef={navRef}
        upperRef={upperRef}
        rarityRef={props.rarityRef}
        open={open}
        handleOpenMenu={handleOpenMenu}
        links={props.links}
      ></LowerNavbar>
    </nav>
  );
}
