import { Link } from "react-router-dom";
import "./HomeButton.scss";

const arrow = "images/icons/arrow.svg";

export default function HomeButton(props) {
  return (
    <Link className="home-link" to="/">
      <button
        className={props.horizontal ? "home-button-horizontal" : "home-button"}
      >
        <object
          aria-label="button arrow"
          className="arrow"
          data={arrow}
        ></object>
        <span className="home-text">Home</span>
      </button>
    </Link>
  );
}
