import "./CardsMenuMobile.scss";
import { Card } from "../CardsMenu/CardsMenu";

export default function CardsMenuMobile(props) {
  return (
    <div className="cards-mobile">
      {Object.entries(props.data).map(
        ([key, { title, value, subtextLine1, subtextLine2 }], index) => {
          return (
            <Card
              key={key}
              title={title}
              subtextLine1={subtextLine1}
              subtextLine2={subtextLine2}
            >
              {value}
            </Card>
          );
        }
      )}
    </div>
  );
}
