import "./MobileMenu.scss";
import { useEffect, useRef, useState } from "react";
import Logo from "../../Common/Logo/Logo";
import MenuButton from "../../Common/MenuButton/MenuButton";
import DiscordLink from "../../Common/DiscordLink/DiscordLink";
import TwitterLink from "../../Common/TwitterLink/TwitterLink";

export default function MobileMenu(props) {
  const [open, setOpen] = useState();
  const menuRef = useRef();

  const handleToggleOpen = () => {
    setOpen(open ? false : true);
  };

  const handleCloseMenu = (e) => {
    e.preventDefault();
    setOpen(false);
    setTimeout(() => {
      window.location.hash = e.target.id;
    }, 300);
  };

  useEffect(() => {
    if (open) {
      menuRef.current.classList.remove("moving-menu-hidden");
    } else {
      menuRef.current.classList.add("moving-menu-hidden");
    }
  }, [open]);

  return (
    <nav className="mobile-menu">
      <div className="static-menu">
        <Logo size="medium"></Logo>
        <MenuButton open={open} onClick={handleToggleOpen}></MenuButton>
      </div>
      <div ref={menuRef} className="moving-menu moving-menu-hidden">
        <div className="social-links">
          <DiscordLink href="hhttps://discord.gg/5Y4mjweQR6" />
          <TwitterLink href="https://twitter.com/BusyBearsClub" />
        </div>
        <div className="section-links">
          {props.links.map(([name, link]) => (
            <a key={name} id={link} href={link} onClick={handleCloseMenu}>
              {name}
            </a>
          ))}
        </div>
      </div>
    </nav>
  );
}
