import "./Counter.scss";
import data from "../../counterData.json";
import CountUp from "react-countup";
import { useEffect, useRef, useState } from "react";
import { useOnScreen } from "../../Utils/utils";

const SingleCouter = (props) => {
  const [finalVal, setFinalVal] = useState(0);
  const [wasShown, setWasShawn] = useState(false);
  const counterRef = useRef();
  const isVisible = useOnScreen(counterRef, "0px", 0.5);

  useEffect(() => {
    if (!wasShown && isVisible) {
      setFinalVal(props.number);
      setWasShawn(true);
    }
  }, [wasShown, isVisible, props.number]);

  return (
    <div ref={counterRef} className="single-counter">
      <div className="text-box">
        <p className="upper-text">{props.children}</p>
        <h4 className="counter-header">{props.title}</h4>
      </div>
      {props.numberFallback ? (
        <span className="number">{props.numberFallback}</span>
      ) : (
        <CountUp
          start={0}
          end={finalVal}
          delay={0}
          duration={1}
          prefix={props.prefix ?? ""}
        >
          {({ countUpRef, start }) => {
            return (
              <span ref={countUpRef} className="number">
                0
              </span>
            );
          }}
        </CountUp>
      )}
    </div>
  );
};

export default function Counter(props) {
  return (
    <div className="couter-container">
      {Object.entries(data).map(
        ([key, { title, text, number, numberFallback, prefix }]) => {
          return (
            <SingleCouter
              key={key}
              title={title}
              number={number}
              numberFallback={numberFallback}
              prefix={prefix}
            >
              {text}
            </SingleCouter>
          );
        }
      )}
    </div>
  );
}
