import "./ClubSection.scss";
import { useEffect, useRef } from "react";
import { useOnScreen } from "../../Utils/utils";
import PaperPara from "../../Common/PaperPara/PaperPara";

const clubImg1 = "images/laptop.png";
const clubImg2 = "images/Bentley.png";

export default function ClubSection(props) {
  const sectionRef = useRef();
  const isVisible = useOnScreen(sectionRef);
  useEffect(() => {
    if (isVisible) props.setActive("story");
  }, [isVisible, props]);
  return (
    <section ref={sectionRef} id="story" className="club-section">
      <div className="left">
        <div className="header-image-club">
          <h2 className="club-header">CLUB</h2>
          <img className="club-img-1" src={clubImg1} alt="header" />
        </div>
        <div className="club-para-1">
          <PaperPara addClass="paper-para-mobile">
            Recently lot of Bears came to the Mad City. They were known as
            intelligent, so they started running a lot of businesses. In a short
            time they became financial leaders, known for smart organization and
            creativity. What will they do next?
          </PaperPara>
        </div>
      </div>
      <div className="right">
        <img alt="second header" className="club-img-2" src={clubImg2} />
        <h3 className="club-para-2">
          If you have an idea for a business trust Busy Bears. They will help you
          to be successful.
        </h3>
      </div>
    </section>
  );
}
