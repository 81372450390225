import "./ThreeSection.scss";
import { useEffect, useRef } from "react";
import { useOnScreen } from "../../Utils/utils";
import { Link } from "react-router-dom";

const arrow = "images/icons/arrow.svg";
const img = "images/DwaNiedzwiedzie.png";

export default function ThreeSection(props) {
  const sectionRef = useRef();
  const linkRef = useRef();
  const isVisible = useOnScreen(sectionRef);
  useEffect(() => {
    if (isVisible) {
      props.setActive("team");
      setTimeout(() => {
        linkRef.current.classList.add("show-active");
      }, 300);
    } else {
      linkRef.current.classList.remove("show-active");
    }
  }, [isVisible, props]);
  return (
    <section ref={sectionRef} id="team" className="three-section">
      <div className="column col-1">
        <p>“Price is what you pay. Value is what you get.”</p>
        <h4>~ Warren Buffett</h4>
      </div>
      <div className="column col-2">
        <Link ref={linkRef} to="/team" className="link-to-team">
          <h4 className="title">Our team</h4>
          <object
            aria-label="button arrow"
            className="arrow"
            data={arrow}
          ></object>
        </Link>
        <p>People with great passion can make the immposible happen.</p>
      </div>
      <div className="column col-3">
        <Link to="/team">
          <img alt="team section" className="side-img" src={img}></img>
        </Link>
      </div>
    </section>
  );
}
