import "./Roadmap.scss";
import { useEffect, useRef } from "react";
import { useOnScreen } from "../../Utils/utils";
// import data from "../../roadmapData.json";
import PaperPara from "../../Common/PaperPara/PaperPara";

const arrow = "images/icons/arrow.svg";
const img = "images/polowa.png";

// const Timeline = (props) => {
//   return (
//     <div className="timeline">
//       {Object.entries(data).map(([key, { value, ending, list }]) => {
//         return (
//           <Segment
//             key={key}
//             ending={ending ? <div className="segment-ending"></div> : null}
//             list={list ?? null}
//           >
//             {value}
//           </Segment>
//         );
//       })}
//     </div>
//   );
// };

// const Segment = (props) => {
//   const segmentRef = useRef();
//   const isVisible = useOnScreen(segmentRef, "0px", 1);
//   const dotRef = useRef();
//   const subtextRef = useRef();

//   useEffect(() => {
//     if (isVisible) {
//       dotRef.current.classList.remove("hidden-dot");
//       subtextRef.current.classList.remove("collapsed");
//     }
//   }, [isVisible]);

//   return (
//     <div ref={segmentRef} id={props.id} className="segment">
//       <div className="graph">
//         <div id={props.id} className="circle">
//           <div ref={dotRef} className="dot hidden-dot"></div>
//         </div>
//         <div className="line"></div>
//         {props.ending ? props.ending : ""}
//       </div>
//       <div ref={subtextRef} className={`subtext`}>
//         <p>{props.children}</p>
//         {props.list ? (
//           <ul>
//             {props.list.map((entry) => (
//               <li key={entry}>{entry}</li>
//             ))}
//           </ul>
//         ) : (
//           ""
//         )}
//       </div>
//     </div>
//   );
// };

export default function Roadmap(props) {
  const sectionRef = useRef();
  const isVisible = useOnScreen(sectionRef);
  useEffect(() => {
    if (isVisible) props.setActive("roadmap");
  }, [isVisible, props]);
  return (
    <section ref={sectionRef} id="roadmap" className="roadmap">
      <a
        href="https://busy-bears.gitbook.io/busy-bears-wp-english/projekt/roadmap"
        className="header-container"
        target="_blank"
        rel="noreferrer noopener"
      >
        <span className="roadmap-header">ROADMAP</span>
        <object
          aria-label="button arrow"
          className="arrow"
          data={arrow}
        ></object>
      </a>
      <div className="roadmap-subsection">
        <img alt="roadmap header" className="roadmap-img" src={img} />
        <div className="roadmap-para">
          <PaperPara addClass="paper-para-mobile">
            As Mad City is still growing, Busy Bears need to always look for new
            partnerships. Having a huge contacts network allows them to
            coordinate most businesses across the city. They are ambitious so
            their plans to grow will not stop at Mad City. It goes much
            further...
          </PaperPara>
        </div>
      </div>
      {/* <Timeline /> */}
    </section>
  );
}
