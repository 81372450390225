import { useEffect, useState } from "react";
import "./CardsMenu.scss";

const logo = "images/icons/justLogo.svg";

export default function CardsMenu(props) {
  const [activeId, setActive] = useState(5);
  const mult = 23.4;
  const [position, setPosition] = useState({
    0: 0,
    1: -1 * mult,
    2: -2 * mult,
    3: -3 * mult,
    4: -4 * mult,
  });

  const handleShow = (cardId) => {
    switch (cardId) {
      case 1:
        setPosition({
          0: 0,
          1: 0 * mult,
          2: -1.3 * mult,
          3: -2.6 * mult,
          4: -4 * mult,
        });
        break;
      case 2:
        setPosition({
          0: 0,
          1: -1 * mult,
          2: -1 * mult,
          3: -2.5 * mult,
          4: -4 * mult,
        });
        break;
      case 3:
        setPosition({
          0: 0,
          1: -1 * mult,
          2: -2 * mult,
          3: -2 * mult,
          4: -3.5 * mult,
        });
        break;
      case 4:
        setPosition({
          0: 0,
          1: -1 * mult,
          2: -2 * mult,
          3: -3 * mult,
          4: -3 * mult,
        });
        break;
      default:
        setPosition({
          0: 0,
          1: -1 * mult,
          2: -2 * mult,
          3: -3 * mult,
          4: -4 * mult,
        });
    }
  };

  const handleMouseEnter = (e) => {
    setActive(Number(e.currentTarget.id));
  };

  const handleMouseExit = (e) => {
    setActive(null);
  };

  useEffect(() => {
    handleShow(activeId);
  }, [activeId]);

  return (
    <div className="cards-menu">
      {Object.entries(props.data).map(
        ([key, { title, value, subtextLine1, subtextLine2 }], index) => {
          return (
            <Card
              key={key}
              id={index + 1}
              onMouseEnter={handleMouseEnter}
              onMouseExit={handleMouseExit}
              zIndex={index + 1}
              x={position[index]}
              title={title}
              subtextLine1={subtextLine1}
              subtextLine2={subtextLine2}
            >
              {value}
            </Card>
          );
        }
      )}
    </div>
  );
}

export function Card(props) {
  const number = props.title.substring(0, props.title.indexOf(" "));
  const titleText = props.title.substring(props.title.indexOf(" ") + 1);
  return (
    <div
      id={props.id}
      className="card"
      onMouseEnter={props.onMouseEnter ?? null}
      onMouseLeave={props.onMouseExit ?? null}
      style={
        props.x
          ? { zIndex: props.zIndex, transform: `translate(${props.x}vw, 0)` }
          : {}
      }
    >
      <div className="card-logo">
        <object aria-label="logo" data={logo}></object>
      </div>
      <div className="dashed"></div>
      <div className="inner-body">
        <div className="title-container">
          <h4 className="number">{number}</h4>
          <h4 className="number-text">{titleText}</h4>
        </div>
        <p className="p-top">{props.children}</p>
        <div>
          <p className="p-bottom">{props.subtextLine1}</p>
          <p className="p-bottom">{props.subtextLine2}</p>
        </div>
      </div>
    </div>
  );
}
