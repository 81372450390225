import { useEffect, useMemo, useState } from "react";

export function useOnScreen(ref, rootMargin = "0px", threshold = 0.5) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        {
          root: document.querySelector("#scrollArea"),
          rootMargin: rootMargin,
          threshold: threshold,
        }
      ),
    [rootMargin, threshold]
  );

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [observer, ref]);

  return isIntersecting;
}

export function scrollToComponent(
  ref,
  containerRef,
  { direction = "verical", delay = 100, offset = 0 }
) {
  if (direction === "horizontal") {
    setTimeout(() => {
      const distance = ref.current.offsetLeft;
      containerRef.current.scroll({
        top: 0,
        left: distance + offset,
        behavior: "smooth",
      });
    }, delay);
  }
  if (direction === "vertical") {
    setTimeout(() => {
      const distance = ref.current.offsetTop;
      if (containerRef.current) {
        containerRef.current.scroll({
          top: distance + offset,
          left: 0,
          behavior: "smooth",
        });
      } else {
        containerRef.scrollTo({
          top: distance + offset,
          left: 0,
          behavior: "smooth",
        });
      }
    }, delay);
  }
}
