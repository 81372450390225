const styles = {
  pagination: {
    display: "flex",
    justifyContent: "center",
    mb: (theme) => theme.spacing(4),
  },
  gridContainer: {
    marginTop: (theme) => theme.spacing(4),
    marginBottom: (theme) => theme.spacing(4),
  },
};

export default styles;
