import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
// import Footer from "../../componentsRanking/Footer/Footer";
import Navbar from "../../componentsRanking/Navbar/Navbar";
import Viewer from "../../componentsRanking/Viewer/Viewer";

function Ranking(props) {
  const [sort, setSort] = useState({ attr: "id", direction: 1 });
  const [searchParams, setSearchParams] = useSearchParams();

  const query = searchParams.get("query") ?? "";

  const handleSearch = (event) => {
    const queryString = event.target.value;
    // window.history.replaceState(
    //   null,
    //   null,
    //   window.location.origin + "/ranking" + `?query=${queryString}`
    // );
    setSearchParams({ query: queryString });
  };

  const handleSortRank = () => {
    const newDir = sort.direction ? 0 : 1;
    setSort({ attr: "rank", direction: newDir });
  };
  const handleSortID = () => {
    const newDir = sort.direction ? 0 : 1;
    setSort({ attr: "id", direction: newDir });
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Container maxWidth="xl">
      <Navbar
        handleSearch={handleSearch}
        handleSortRank={handleSortRank}
        handleSortID={handleSortID}
        value={query}
      ></Navbar>
      <Viewer query={query} sort={sort}></Viewer>
    </Container>
  );
}

export default Ranking;
