import { Link } from "react-router-dom";
import "./Collection.scss";

const img1 = "images/collection-1.png";
const img2 = "images/collection-2.png";
const gifImg = "images/BBCollection.png";
const arrow = "images/icons/arrow.svg";

export default function Collection(props) {
  return (
    <section id="collection">
      <div className="collection-container">
        <div className="sub-grid-1">
          <img alt="collection wrapped" src={img1} />
          <div>
            <span>THE</span>
          </div>
          <div>
            <span className="grid-span-2 cutout-text bottom-text">COLLEC</span>
          </div>

          <h2 style={{ display: "none" }}>collection</h2>
        </div>
        <img alt="collection wrapped second" className="big-image" src={img2} />

        <img
          alt="collection wrapped bottom"
          className="bottom-image"
          src={gifImg}
        />
        <div className="lower-text">
          <span className="cutout-text">TION</span>
          <Link to="/ranking" className="collection-button">
            <span className="button-text">RANKING</span>
            <object
              aria-label="button arrow"
              className="arrow"
              data={arrow}
            ></object>
          </Link>
        </div>
      </div>
    </section>
  );
}
