import { Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import Ranking from "../Pages/Ranking/Ranking";
import Team from "../Pages/Team";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/team" element={<Team />}></Route>
        <Route path="/ranking" element={<Ranking />}></Route>
      </Routes>
    </div>
  );
}

export default App;
