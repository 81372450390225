import { useCallback, useEffect, useRef, useState } from "react";
import PaperPara from "../../Common/PaperPara/PaperPara";
import Footer from "../../Common/Footer/Footer";
import "./Slider.scss";
import data from "../../teamsData.json";
import useMediaQuery from "../../Utils/mediaQueries";
import { scrollToComponent } from "../../Utils/utils";
import DiscordLink from "../../Common/DiscordLink/DiscordLink";
import TwitterLink from "../../Common/TwitterLink/TwitterLink";

export const LandingComponent = (props) => {
  return (
    <div className="component-landing">
      <div className="component-text">
        <h1 className="landing-title">{props.title}</h1>
        <PaperPara addClass={"paper-para-team paper-para-mobile"}>
          {props.children}
        </PaperPara>
      </div>
    </div>
  );
};

export const Component = (props) => {
  const { title, subTitle, value, imgUrl, twitterUrl, discordUrl } = props.data;
  const contentRef = useRef();
  const componentRef = useRef();
  const barRef = useRef();

  const handeSetActive = (e) => {
    const currentId = e.currentTarget.id;
    if (currentId === props.activeCard) {
      props.setActive(null);
      return;
    }
    props.setActive(currentId);
  };

  const handleOpen = useCallback(() => {
    if (props.activeCard === props.id) {
      contentRef.current.classList.add("content-show");
      barRef.current.classList.add("dark");
    } else {
      contentRef.current.classList.remove("content-show");
      barRef.current.classList.remove("dark");
    }
  }, [props.activeCard, props.id]);

  const handleScrollToSectionHorizontal = useCallback(() => {
    if (props.query.matches) return;
    scrollToComponent(componentRef, props.containerRef, {
      direction: "horizontal",
      delay: 310,
      offset: -160,
    });
  }, [props.query.matches, componentRef, props.containerRef]);

  const handleScrollToSectionVertical = useCallback(() => {
    if (!props.query.matches) return;
    scrollToComponent(componentRef, window, {
      direction: "vertical",
      delay: 310,
      offset: -110,
    });
  }, [props.query.matches]);

  useEffect(() => {
    handleOpen();
    if (props.id === props.activeCard) {
      handleScrollToSectionHorizontal();
      handleScrollToSectionVertical();
    }
  }, [
    props.activeCard,
    props.id,
    handleOpen,
    handleScrollToSectionHorizontal,
    handleScrollToSectionVertical,
  ]);

  return (
    <section ref={componentRef} id={props.id} className="component">
      <div ref={barRef} id={props.id} onClick={handeSetActive} className="bar">
        <span className="bar-title">{title}</span>
        <span className="bar-desc">{`${subTitle}`}</span>
      </div>
      <div ref={contentRef} className="content-container">
        <div className="text-fixed">
          <h2 className="sub-title">{title}</h2>
          <div className="floating-container">
            <img alt="avatar" src={imgUrl} className="content-img"></img>
            <PaperPara addClass={"paper-para-team paper-para-mobile"}>
              {value}
            </PaperPara>
          </div>
          <div className="social-links">
            {discordUrl ? <DiscordLink href={discordUrl} /> : null}
            {twitterUrl ? <TwitterLink href={twitterUrl} /> : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default function SliderMain(props) {
  const [activeCard, setActiveCard] = useState(null);
  const mainRef = useRef();
  const smallDesktopQuery = useMediaQuery("smallDesktop");

  const handleScroll = (e) => {
    e.preventDefault();
    mainRef.current.scrollLeft += e.deltaY / 4;
  };

  useEffect(() => {
    const localRef = mainRef;
    if (localRef && !smallDesktopQuery.matches) {
      localRef.current.addEventListener("wheel", handleScroll);
    }
  }, [mainRef, smallDesktopQuery]);

  return (
    <div ref={mainRef} className="team-container">
      <LandingComponent title="TEAM">
        Meet our team - Busy Bears responsible for the best successful
        businesses in Mad City. True leaders, investors, and brokers who can
        wait, observe and take action when the time comes. That is the secret
        key.
      </LandingComponent>
      {Object.entries(data).map(([key, data], index) => {
        return (
          <Component
            key={key}
            id={key}
            index={index}
            data={data}
            containerRef={mainRef}
            query={smallDesktopQuery}
            activeCard={activeCard}
            setActive={setActiveCard}
          />
        );
      })}
      <Footer addClass="footer-team-page" />
    </div>
  );
}
