import ClubSection from "../ComponentsHome/ClubSection/ClubSection";
import Collection from "../ComponentsHome/Collection/Collection";
import Counter from "../ComponentsHome/Counter/Counter";
import Footer from "../Common/Footer/Footer";
import HeroBanner from "../ComponentsHome/HeroBanner/HeroBanner";
import MintSection from "../ComponentsHome/MintSection/MintSection";
import Navigation from "../ComponentsHome/Navigation/Navigation";
import RaritySlider from "../ComponentsHome/RaritySlider/RaritySlider";
import Roadmap from "../ComponentsHome/RoadmapSection/Roadmap";
import ThreeSection from "../ComponentsHome/ThreeSection/ThreeSection";
import { useRef, useState } from "react";
import useMediaQuery from "../Utils/mediaQueries";
import MobileMenu from "../ComponentsHome/MobileMenu/MobileMenu";

const links = [
  ["Story", "#story"],
  ["Buy", "#mint"],
  ["Roadmap", "#roadmap"],
  ["Rarity", "#rarity"],
  ["Team", "#team"],
];

export default function Home(props) {
  const [activeSection, setActive] = useState(null);
  const rarityRef = useRef();
  const mobileQuery = useMediaQuery("mobile");

  return (
    <>
      <header>
        {mobileQuery.matches ? (
          <MobileMenu links={links}></MobileMenu>
        ) : (
          <Navigation
            links={links}
            activeSection={activeSection}
            rarityRef={rarityRef}
          ></Navigation>
        )}
      </header>
      <HeroBanner />
      <ClubSection setActive={setActive} />
      <MintSection setActive={setActive} />
      <Roadmap setActive={setActive} />
      <Counter />
      <Collection />
      <RaritySlider refIn={rarityRef} setActive={setActive} />
      <ThreeSection setActive={setActive} />
      <Footer />
    </>
  );
}
