import "../SocialLinkStyles.scss";
const twitter = "images/icons/twitter.png";

export default function TwitterLink(props) {
  if (props.textLink) {
    return (
      <a
        href={props.href}
        className={`social-link-text ${props.className ?? ""}`}
      >
        Twitter
      </a>
    );
  } else {
    return (
      <a href={props.href} className={`social-link ${props.className ?? ""}`}>
        <object aria-label="twitter link" data={twitter}></object>
      </a>
    );
  }
}
