import NavSide from "../ComponentsTeam/NavSide/NavSide";
import SliderMain from "../ComponentsTeam/TeamContainer/Slider";
import "./Team.scss";

export default function Team(props) {
  return (
    <div className="origin">
      <header>
        <NavSide></NavSide>
      </header>
      <SliderMain></SliderMain>
    </div>
  );
}
