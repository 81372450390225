import Logo from "../../Common/Logo/Logo";
import DiscordLink from "../DiscordLink/DiscordLink";
import TwitterLink from "../TwitterLink/TwitterLink";
import CronoscanLink from "../CronoscanLink/CronoscanLink";
import "./Footer.scss";

// const medium = "images/icons/medium.png";
// const telegram = "images/icons/telegram.png";

const LogoLinks = (props) => {
  return (
    <div className="links logo-links">
      <DiscordLink href="https://discord.gg/5Y4mjweQR6" />
      <TwitterLink href="https://twitter.com/BusyBearsClub" />
    </div>
  );
};

const TextLinks = (props) => {
  return (
    <div className="links text-links">
      <DiscordLink textLink={true} href="https://discord.gg/5Y4mjweQR6" />
      <TwitterLink textLink={true} href="https://twitter.com/BusyBearsClub" />
      <CronoscanLink textLink={true} href="https://cronoscan.com/token/0x4e42d23d4ca068bebe6985bcef741dc0ff87abb2" />
    </div>
  );
};

export default function Footer(props) {
  return (
    <footer className={`footer ${props.addClass ?? ""}`}>
      <div className="upper-row">
        <Logo className="logo" addClass="footer-logo"></Logo>
        {props.logo ? <LogoLinks /> : <TextLinks />}
      </div>
      {/* <div className="lower-row">
        <div className="info">
          <span>© 2022 Bartosz Pietrzak — </span>
          <a href="https://github.com/Bar0-dev">GitHub</a>
        </div>
      </div> */}
    </footer>
  );
}
