const getByQuery = (metadata, id) => {
  const data = metadata.filter((entry) =>
    entry.name.toLocaleLowerCase().includes(String(id).toLocaleLowerCase())
  );
  return data;
};

const sortArr = (metadata, options) => {
  const { attr, direction } = options;
  if (!attr) return metadata;
  if (attr === "id") {
    const data = [...metadata].sort((a, b) => {
      if (direction) return a.id - b.id;
      return b.id - a.id;
    });
    return data;
  }
  if (attr === "rank") {
    const data = [...metadata].sort((a, b) => {
      if (direction) return a.rank - b.rank;
      return b.rank - a.rank;
    });
    return data;
  }
};

export { getByQuery, sortArr };
