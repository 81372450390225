import "./RaritySlider.scss";
import data from "../../raritySliderData.json";
import { useEffect, useRef } from "react";
import { useOnScreen } from "../../Utils/utils";

const dataArrSingle = Object.entries(data);

const dataArr = [
  ...dataArrSingle,
  ...dataArrSingle.map(([key, value], index) => {
    return [key + index, value];
  }),
];

const SliderComponent = (props) => {
  return (
    <div className="slider-component">
      <span className="title">{props.title}:</span>
      <div className="description">
        <span>{props.children}</span>
      </div>
    </div>
  );
};

export default function RaritySlider(props) {
  const sectionRef = useRef();
  const isVisible = useOnScreen(sectionRef);

  useEffect(() => {
    if (isVisible) props.setActive("rarity");
  }, [isVisible, props]);

  return (
    <section ref={sectionRef} id="rarity" className="rarity-section">
      <div ref={props.refIn} className="slider-container">
        <div className="slider">
          <div className="component-wrapper">
            {dataArr.map(([key, { title, value }]) => (
              <SliderComponent key={key} title={title}>
                {value}
              </SliderComponent>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
