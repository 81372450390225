import "./PaperPara.scss";

export default function PaperPara(props) {
  return (
    <div className={`${props.addClass}`}>
      <span
        className="floating"
        style={props.size ? { fontSize: `${props.size * 4}em` } : {}}
      >
        {props.children[0]}
      </span>
      <p
        className="para-big"
        style={props.size ? { fontSize: `${props.size}em` } : {}}
      >
        {props.children.slice(1, props.children.length)}
      </p>
    </div>
  );
}
