import { useCallback, useEffect, useState } from "react";

const mobile = "480px";
const mobileBig = "650px";
const tablet = "768px";
const smallDesktop = "1200px";

export const createQuery = (size) => {
  if (size === "mobile") return window.matchMedia(`(max-width: ${mobile})`);
  if (size === "mobileBig")
    return window.matchMedia(`(max-width: ${mobileBig})`);
  if (size === "tablet") return window.matchMedia(`(max-width: ${tablet})`);
  if (size === "smallDesktop")
    return window.matchMedia(`(max-width: ${smallDesktop})`);
  console.log('Chose between "mobile", "tablet", "smallDesktop"');
  return false;
};

export default function useMediaQuery(size) {
  const [resized, setResized] = useState(true);
  const [mobileQuery, setMobileQuery] = useState(createQuery(size));
  const handleResize = useCallback(() => {
    setResized(true);
    setMobileQuery(createQuery(size));
  }, [size]);
  useEffect(() => {
    if (resized) setResized(false);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [resized, handleResize]);
  return mobileQuery;
}
